/* Set background and font colors */
body {
    background-color: black;
    color: white;
  }
  
  /* Add transition effect when page is scrolled */
  body {
    transition: all 0.5s ease-in-out;
    scroll-behavior: smooth;
  }
  
  /* Change background color and move text on hover */
  .homepage-text:hover {
    background: linear-gradient(to left, blue, black);
    background-size: 300% 200%;
    background-position: right bottom;
    transition: all .8s ease-out;
    color:linear-gradient(to right, white, black);
    border-radius: 32px;
    // box-shadow: 20px;
    transform: translateX(18px) translateY(-18px);
    // font-weight: bold;
  }
  .homepage-text{
    font-size: 28px;
    line-height: 2;
    padding: 70px;

  }
  
  .homepage-text:active {
    background-position: left bottom;
    transition: all 0.4s ease-out;
    color: white;
    margin: 8px;
    padding:8px
  }
  
  /* Add box shadow to homepage title */
  .homepage-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    margin: 24px;
    padding: 24px;
    font-weight: bolder;
    text-shadow: 2px 2px #3498db;
  }
  
  /* Add box shadow to container */
  .homepage-container {
    background-color: black;
    color:white;
    line-height: 2;
    font-size: 26px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  }
  
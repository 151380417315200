//Product Feed
.product-feed {
  width: 100%
}
.product-index{
  // margin-bottom: 70px;
  width: 100%;
  padding-left: 20px;
}

//Products Index
.product-index-container {
  display: flex;
  // min-height: 1200px;
  background-color: $white;
  justify-content: space-between;
  // white-space: 20px;
  // width: 70%;
  // margin: 0 auto;
  // border: 1px solid $gray;
  border-radius: 8px;
}
.product-nav-container {
  position: relative;
  background-color: $background-gray;
  border-radius: 4px;
  width :100%;
  // margin: 0;
  // border-radius: 10px;
  // border: 2;
  // right: 40px;
}
.product-nav-item {
  display: block;
  width: 100%;
  color: $text-gray;
  font-size: 15px;
  padding: 5px;
  
  // margin: 3px;
  box-sizing: border-box;
  // border-radius: 5px;
  // border: 1px solid $background-gray;
}

.product-index-header {
  height: 21px;
  padding: 15px 15px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-index-header-border {
  border-bottom: 1px solid $gray;
  height: 1px;
  margin: 0 15px;
}

.product-index-header-text {
  font-size: 20px;
}

.product-index-header-popular-newest {

}

@media all and (max-width: 1100px) {
  .product-feed {
    width: 100%
  }
  .product-index-item{
    background-color: white;
    width: 100%;
    height: 100%;
    padding-left: 0;
    padding-right:0;
    padding-top:10px;
    padding-bottom: 10px;
    margin-left:2px;
    margin-right:2px;
  }
  .product-tagline{
    display: none;
  }
  .product-index-header {
    background-color: $background-gray;
    width:100%;
  }
  .product-index-container{

    display: flex;
    flex-direction: column;
    width:100%;
  }
  .product-index{
    // margin-bottom: 70px;
    padding-left: 0;
    width: 100%;
    
  }
}
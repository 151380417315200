.terms-container {
    color: black;
    font-size: 16px;
    line-height: 1.5;
  }
  .TermsOfUse {
    margin: 40px;
    padding: 40px;
  }
  
  .terms-heading {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .terms-subheading {
    font-size: 24px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  
  .terms-text {
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  .terms-list {
    list-style: none;
    margin-bottom: 20px;
  }
  
  .terms-list-item:before {
    content: "•";
    margin-right: 10px;
  }
  
  .terms-link {
    color: black;
    text-decoration: underline;
  }
  
  .terms-link:hover {
    color: black;
    text-decoration: none;
  }
  
  .terms-highlight {
    color: black;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .terms-heading {
      font-size: 28px;
    }
    
    .terms-subheading {
      font-size: 20px;
    }
    
    .terms-text {
      font-size: 14px;
    }
    
    .terms-list-item:before {
      font-size: 12px;
    }
  }
  
.app-content {
  max-width: 1400px;
  // width: 100%;
  margin: 40px auto;
margin-top: 90px;
  display: flex;
  justify-content: space-between;
// height: 100%;
}

.product-nav-container {
  position: relative;
  background-color: $background-gray;
  border-radius: 4px;
  width :25%;
  
  //test
  // border: 1px solid red;
}

.product-feed-container {
  display:flex;
  width: 100%;

  //test
  // border: 1px solid red;
}

.product-ad-container {
  width: 18%;
  height: 220px;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 3px;

  //test
  // border: 1px solid red;
}

.footer {
  background-color: black;
  color: white;
  // padding: 20px;
  font-size: 16px;
  padding-top: 0;
  padding-bottom: 20px;
  padding-left: 60px;
}

li {
  margin-top: 5px;
}

.row {
  display: flex;
  justify-content:space-between;
}

.contact, .links {
  flex-basis: 15%;
}

.legal {
  margin-top: 10px;
  color: white;
  font-size: 16px;
}

.icon-img {
  // display: block;
  width: 18px;
  height: 18px;
  // margin: 0 auto;
}
.icon-link{
  display: flex;
  flex-direction:row;
  text-align: center;
  padding-top: 10px;
  justify-content: left;
  height: 22px;
}

.icon-text{
  color: white;
  font-size: 16px;
  padding-left: 5px;
}

@media all and (max-width: 1100px) {
  .product-nav-container {
    // display: none;
  }
  // .product-nav{
  //   display: none;
  // }

  .product-feed-container {
    width: 100%;
  }

  .product-ad-container {
    display: none;
  }
  .footer {
    padding:15px;
    // margin: 5px;
  }
  .app-content {
    margin-top: 160px;
  }
}

//Product  Nav

.product-nav {
  display: flex;
  flex-direction: column;
  width: 90%;
  // border: 3px solid $white;
  background-color: $white;
  margin-right: 4px;
}
.product-nav-container {
  width: 20%;
}

.product-nav-header {

  color: $text-gray;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 5px;
  padding: 5px;
}

.product-nav-item {
  display: block;
  width: 100%;
  color: $text-gray;
  font-size: 18px;
  padding: 5px;
  margin: 3px;
  box-sizing: border-box;
  text-transform: capitalize;
  
  // border-radius: 5px;
  // border: 1px solid $background-gray;
}

.product-nav-item:hover {
  border: 1px solid $gray;
  text-decoration: none;
  background-color: $gray;
}

// .product-nav-selected {
//   width: 100%;
//   color: $black;
//   // border: 1px solid $gray;
//   background-color: $white;
  
//   //test
//   // border: 1px solid red;
// }

@media all and (max-width: 1100px) {
  .product-nav-container {
    // display: hide;
    width:100%;
    height: 100%;

  }
  .product-nav-item{
    display: table;
width:150%;
font-size:14px;
  }

  // .product-nav-item:hover {
  //   display: none;
  // }
  .product-nav{
    // display: none;
    // width
  }

  .product-nav-list{
    width: 90%;
    // display: grid;
    display: flex;
    max-height: 70px;
    overflow:hidden;
    overflow-x:auto;
    // position: absolute;
    
    }
    .product-nav-list li{
      display: inline;
      float:left;
      padding-right: 40px;
      // position: relative;
      width:120px;
      
    }
    
    // .product-nav-list li ~ li{
    //   display: none;
    //   }
    // .product-nav-list:hover li ~ li{
    //   display: grid;
    //   }

}
//ue === UserEdit

.ue-form-container {
  width: 1070px;
  margin: 0 auto;
}

.ue-form-header {
  margin: 10px 0;
}

.ue-form {
  display: flex;
  flex-direction: column;
  align-items: left; 
  height: 476px;
  background-color: black;
  border: 1px solid $gray;
  width: 600px;
  padding: 20px;
  color: white;
  //test
  // border: 1px solid red;
}

.ue-form p {
  text-align: center;
}

.ue-form h2 {
  text-align: center;
}

.ue-form-errors {
  padding-top: 10px;
  color: $orange;
}

.ue-form-input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;

  //test
  // border: 1px solid red;
}

.ue-form-input {
  font-size: 24px;
  font-weight: bolder;
  width: 90%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $gray;
  margin-bottom: 10px;
  padding: 10px;
  //test
  // border: 1px solid red;
}

.ue-form-input label {
  color: $text-gray;
  font-size: 16px;
}

.ue-form-input input {
  color: black;
  width: 60%;
}

.ue-form-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  margin: 0;

  //test
  // border: 1px

}

@media all and (max-width: 1100px) {
  .ue-form-container {
    width: 90%;
    margin: 0 auto;
  }
}
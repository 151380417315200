// $light-orange: #3B5DD6;
$light-orange:black;
// $orange: #2348CB;
$orange: black;

$white: #FFFFFF;
$gray: #F7F7F7;
$background-gray: #F6F8FF;
$input-background-gray: #EEF1FF;
$text-gray: #BCC0CF;
$dark-gray: #5A627B;

$gray-blue: #6d6a6a;
$black: #302F2F;
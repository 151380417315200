$primary-color: #000000;
$secondary-color: #333333;

.termsHeader {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: $primary-color;
  display: flex;
  align-items: center;
  justify-content: center;
}

.termsSecondHeader {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: $primary-color;
  display: flex;
  margin-left: 100px;
}

.termsParagraph {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  color: $secondary-color;
  margin-left: 100px;
  margin-right: 100px;
  line-height: 1.5;
}

.termsContent {
    margin-left: 100px;
    margin-right: 100px;
  margin-bottom: 1.5rem;
  list-style: disc;
  list-style-position: inside;
  
  .termsContentLI {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: $secondary-color;
  }
}

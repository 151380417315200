.hero-banner {
  width: 100%;
  background-color: $white;
  margin-top: 70px;
}

.hero-banner-container {
  // width: 1070px;
  padding: 30px 30px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  row-gap: 40px;
  margin-top: 150px;
}

.hero-banner-content {

  // width: 500px;
  // padding-left:20px
  // margin-right: 50px;
  // margin-right: 260px;
}
.heroCollage{
  padding-left: 100px;
  background-size: contain;
  pointer-events: none;
  
}
.heroCollage > div >{
  transform: translate3d(0, 0, 0);
}

.heroCollage > div > div{
  animation: moveSlideshow 12s linear infinite;
}

.hero-banner-title {
  display: block;
  margin-bottom: 15px;
  font-size: 26px;
  line-height: 31px;
  font-weight: 700;
  margin-left: 5px;
}

.hero-banner-text {
  width: 100%;
  font-size: 15px;
  line-height: 25px;
  color: #999;
  font-weight: 200;
  margin: 10px 0 20px 5px;
}

.hero-banner-img {
  width: 280px;
  height: 230px;
  // min-width: 240px;
  // min-height: 220px;
  // background-image: url('https://res.cloudinary.com/dekgrddbo/image/upload/v1506108319/ph_kitty_nobg_g9mblw.webp');
  background-repeat: no-repeat;
  background-position: center; 
  background-size: 100%;
}

@media all and (max-width: 1100px) {
  .heroCollage {
    display: none;
  }
  .hero-banner {
    margin-top: 130px;
  }
  .hero-banner-container{
    margin-top: 200px;
  }
}
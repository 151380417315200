.subscription-cards {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
  }

  .subscription-card {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .subs{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .subs2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  ul{
    list-style: circle;
  }
  .subscriptionListItem{
    font-size: 18px;
    margin: 18px;
    text-align: left;
  }
  .card {
    width: calc(33.33% - 10px);
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.24);
  }
  
  .card-header {
    background-color: #000;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    padding: 20px;
  }
  
  .card-body {
    padding: 30px;
    text-align: center;
  }
  
  .card-title {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 30px;
    color: #000;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin-bottom: 30px;
  }
  
  li {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  
  .btn-primary {
    background-color: #000;
    border-color: #000;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 4px;
    transition: all 0.3s ease;
  }
  
  .btn-primary:hover {
    background-color: #fff;
    color: #000;
  }
  
  
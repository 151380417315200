.search-bar-container {
  // display:list-item;
  // border: 5px solid $gray;
  // border-radius: 3px;
  // padding: 0 8px;
  color: $dark-gray;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
}

.search-bar {
  border: none;
}

.searchDropdown{
  margin-top: 5px;
  height: 20px;
  width: 500px;
  // padding: 2px 0px;
  color: $dark-gray;
  // box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 0px;
  // overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
  display: flex;
  align-items: center;
  color: $dark-gray;
  overflow: hidden;
}

.searchResultButton{
 width: 100px;
 height: 30px;
 display: flex;
 align-items: center;
 color: $dark-gray;
 overflow: hidden;
}

.searchResultButton button:hover{
background-color: lightblue;
}
.dataResult {
  margin-top: 35px;
  align-items: center;
  width: 340px;
  height: 150px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
}

.search-bar .dataResult{
  display: block;
  /* Display the dropdown */
}

@media all and (max-width: 1100px) {
  .search-bar-container {
    width:94%
  }
}
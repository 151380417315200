

.hide {
  display: none;
}

.dropdown-content {
	position: absolute;
	top: 72px;
	// right: calc((100% - 1070px)/2);
	background-color: black;
	border: 1px solid $gray;
	border-radius: 3px;
	padding: 10px;
	z-index: 20;
	color: white;
	font-family: $sans-serif;
	text-align: left;
	box-shadow: -1px 4px 6px 1px rgba(0,0,0,0.09);
	font-weight: initial;
}

.dropdown-item  {
  color: $text-gray;
  font-size: 14px;
  font-weight: 400;
  padding: 5px;
}

.dropdown-item:hover {
  cursor: pointer;
  text-decoration: underline;
}

.dropdown-item:last-child {
  border-top: 1px solid $gray;
}

@media all and (max-width: 1100px) {
  [class*='col-'] {
    width: 100%;
	}
	
	.dropdown-content {
		width:50px;
		position: relative;
		right: 2.5%;
		top:0px;
		// display:gr;
		// padding-top: 50px;
	}
}
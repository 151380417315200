.dropdown1 {
    position: relative;
    display: inline-block;
  }
  
  .dropdown1-toggle {
    background-color: black;
    border: none;
    color: white;
    // padding: 8px 16px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .dropdown1-menu {
    position: absolute;
    z-index: 1004;
    // display: none;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .dropdown1-menu.show {
    display: block;
  }
  
  .dropdown1-item {
    padding: 4px 8px;
    // border-bottom: #333;
    box-shadow: #333;
    font-size: 18px;
    color: #333;
    cursor: pointer;
  }
  
  .dropdown1-item:hover {
    background-color: #f5f5f5;
  }
  @media all and (max-width: 1100px) {
    .dropdown1-toggle{
        font-size: 14px;   
    }
  }
.session-form {
  // width: 100%;
  max-width: 800px;
  height: 100%;
  padding-top: 80px;
  background: rgba(247, 247, 247, .8);
  opacity: 1;
  margin: 0 auto;
  // padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; 

  //test
  // border: 1px solid red;
}
.forgot .forgotPassword {

font-size: 16px;
}

.thankyou {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  margin-top: 20px;
}

.session-form * {
  margin-left: 5px;
  margin-bottom: 16px;
  font-size: 20px;
  // test
  // border: 1px solid red;
}
.PhoneInputInput {
  min-width:fit-content;
}

.emailInput input {
width: 350px;
}

.session-form p {
  text-align: center;
}

.session-form h2 {
  text-align: center;
  font-size: 26px;
  color: $orange;
  font-weight: bold;
}

.session-errors {
  width:40%;
  padding-top: 0px;
  padding-left: 80px;
  font-size:18px;
  color: $orange;
}

.session-form-img {
  height: 200px;
  width: 300px;
  // max-width: 140px;
  // max-height: 120px;
  
}

.form-input-container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding-right: 71px;

  //test
  // border: 1px solid red;
}

.form-input {
  display: flex;
  width:130%;
  justify-content: space-between;
  font-size: 18px;
  // font-weight: bold;
  //test
  // border: 1px solid red;
}
.form-input input{
  width:120%;
  font-size: 15px;
}

.session-btn-nav {
  display: flex;
  justify-content: space-between;

  //test
  // border: 1px solid red;
}
.session-btn-nav-signin-up{
  display:inline;
  padding-left: 130px;
}

.session-btn-nav-signin-up-text{
  padding-top: 50px;
font-size: 15px;
padding-right: 0px;
}

.logoBack{
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  height: 100px;
  background-color: black;
  color: white;
  font-size: 32px;
}
.phoneInput{
  width: 440px;
  margin-top: 10px;
  // height:300px
}

// .PhoneInputCountry{
//   height: 150%;
// }
.phoneNumberInput{
  display: flex;
  justify-content: space-between;
}
.submitButtonContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}

@media all and (max-width: 1100px) {
  .session-form{
    
    width: 100%;
    height:100%;
    // position:absolute;
    // width: 300%;
    // margin-left: 0;
    align-items:start;
    
  }
  .form-input{
    // white-space: normal;
    display: flex;
    // margin-right: 20px;
    justify-content: left;
  }
  .form-input input{
    // margin-right: 2px;
    // display: flex;
    // justify-content: left;
    // padding-left: 0;
    max-width:180px;
    // width: 0px;
    font-size: 16px;
  }
  .form-input-container{
    padding-right: 0;

  }
  .session-btn-nav-signin-up{
    padding-left: 75px;
  }
  .phoneInput{
    width: 50%;
    margin-top: 20px;
    // margin-left: 40px;
    height:520px
  }
  .phoneNumberInput{
    display: block;
    margin-left: 4px;
    margin-right: 4px;
  }
}
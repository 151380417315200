
.product-index-item {
  display: flex;
  padding: 15px;
  height: 150px;
  border-bottom: 4px solid $gray;
}

.product-index-item:hover {
  background-color: $background-gray;
  cursor: pointer;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  display:grid;
  background-color: $white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
}
.popup-close-button{
  display: flex;
  align-self: right;
  justify-self: right;
  // justify-content: right;
}

.popup-email-input{
  background-color: $input-background-gray;
  font-size: 20px;
  text-align: left;
  height: 32px;
  margin-bottom: 22px;
}

.product-index-item-img {
  display: flex;
  height: 160px;
  width: 200px;
}
.product-index-image {
  align-items: start;
  height: 100%;
  width: 100%;
  object-fit: contain
}
.product-index-item-text {
  margin-left: 10px;
  width: 100%;
  // display: flex;
  // grid-gap: 40px;
  flex-direction: column;
  justify-content: space-around;


  //test
  // border: 1px solid red;
}
.product-index-image-icon{
  height: 30px;
}

.product-name {
  font-size: 20px;
}

.product-item-buttons {
  width: 100%;
  
  //test
  // border: 1px solid red;
}

.product-item-minor-actions {
  display: flex;
  justify-content: space-between;
  width: 110px;
}

.product-tagline {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  color: $text-gray;
}

.product-item-buttons {
  display: flex;
  justify-content: space-between;
}
.upvotelistbutton{
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height:30px;
  margin-left:25px
}

@media all and (max-width: 1100px) {
  
  .product-index-item-text{
    height: 100%;
    width: 100%;
  }
  .product-item-buttons {
    // margin-bottom: 0px;
    padding-top: 5px;
    justify-content:left;
    align-items: center;
  }
  .product-item-minor-actions {
    margin-right: 0px;
  }
  .product-name {
    display: flex;
  flex-wrap: wrap;
 
  }
}
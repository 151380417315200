//User Profile
.user-profile {
  max-width: 1070px;
  width: 95%;
  margin: 0 auto;
}
.user-headline{
  font-size: 20px;
  margin-top: 30px;
  // margin-bottom: 30px;
}
//User Profile Header
.user-header {
  width: 100%;
  height: 567px;
  // margin: 25px auto;
  // padding: 0 85px;
  border-radius: 3px;
  background-color: black;
  
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;
}

.user-header-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-header-profile-img-container {
  width: 140px;
  height: 140px;
}

.user-header-profile-img {
  border-radius: 100%;
}

.user-profile-main-text-contianer {
  // height: 100px;
  margin-top: 30px;
  margin-bottom: 30px;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.user-profile-main-text {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.user-username {
  font-size: 28px;
  font-weight: bold;
}

.user-userslug {
  line-height: 1.5;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 18px
}

.user-tagline {

}

.user-profile-links {
  width: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-profile-link {
  color: $white;
  font-size: 14px;
}

//User Profile Content
.user-profile-content-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.user-profile-index-container {
  width: 100%;

  //test
  // border: 1px solid red;
}

//User Profile Nav
.user-profile-nav-container {
  width: 165px;
  
  //test
  // border: 1px solid red;
}

.user-profile-nav {
  display: flex;
  flex-direction: column;
}

.user-profile-nav-item {
  margin: 5px;
}

.user-profile-nav-item * {
  color: $text-gray;
  font-size: 13px;
  padding: 5px;
}

.selected {
  background-color: $gray;
  border-radius: 5px;
  color: $black;

  //test
  // border: 1px solid red;
}

//User Profile Add Bar
.user-profile-ad-container {
  width: 230px;
  height: 200px;
  background-color: $white;
  border: 1px solid $gray;

  //test
  // border: 1px solid red;
}

@media all and (max-width: 1100px) {
  .user-profile-index-container {
    width: 80%
  }

  .user-profile-nav-container {
    width: 17%;
  }

  .user-profile-ad-container {
    display: none;
  }
}
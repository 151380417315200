.modal {
  // padding-top: 200px;
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border: 1px solid #ccc;
  background: rgba(128,128,128,0);
  overflow: auto;
  webkit-overflow-scrolling: touch;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  // width: 40%;
  margin: 0 auto; 
  // height: 80%;
  // min-height: 60px;
  height: 620px;
  max-width:800px;

  //test
  border: 0;
}

.modal_after-open {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  webkit-overflow-scrolling: touch;
  border-radius: 4px;
  outline: none;
  // padding: 20px;

  display: block;
}

.modal_before-close {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  webkit-overflow-scrolling: touch;
  border-radius: 4px;
  outline: none;
  padding: 20px;

  display: block;
}

@media all and (max-width: 1100px) {
  .modal{
    // justify-content: center;
    width: 75%;
    height: 60%;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 0px;
    padding-top: 150px;
    // margin: 0;
    // margin-left: 0px;
    // margin-right: 0.01em;
  }
}
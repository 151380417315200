.privacy-policy-container {
    background-color: white;
    color: black;
    padding: 20px;
    margin: 20px;
    border-radius: 30px;
  }
  
  .privacy-policy-title {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .privacy-policy-subtitle {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .privacy-policy-text {
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 1.5;
  }
  
  .privacy-policy-list {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .privacy-policy-list-item {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
.main-nav {
	background-color: $orange;
	display:flex;
	// justify-content: space-between;
	align-items: center;
	// padding: 9px;
	margin-left: 20px;
	margin-right:500px;
	min-height: 50px;
	height: 9%;
	// max-width: 1070px;
	width: 0px;
	background-color: $white;
	padding-bottom: 9px;
	padding-top: 9px;
	// margin: 0 auto;
	font-size: 40px;
	font-weight: 300%;
	text-align: center;
	color: white;
	text-shadow: $orange;
}
.headerColor{
	display:flex;
	background-color: black;
	height: 150px;
	justify-content:space-between;
	// justify-content: space-between;
	// width: 100%;
}
.headerLinks{
	display:flex;
	justify-content: right;
	flex-direction:row;
	margin-left: 800px;
	// padding-left: 900px;
}
.locationFilter{
	align-self: center;
	padding-right: 50px;
	display: flex;
	// width:50px;
	height: 40px;
	width:150px
	

}
.dropdown {
	position: relative;
	background-color: black;
	width:100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	
  }
  .dropdown > button {
	font-size: 18px;
  }
  
  .menu {
	position: absolute;
	z-index: 5;
  
	list-style-type: none;
	margin: 55px 0;
	padding: 0;
  
	border: 1px solid grey;
	width: 150px;
  }
  
  .menu > li {
	margin: 0;
  
	background-color: white;
  }
  
  .menu > li:hover {
	background-color: lightgray;
  }
  
  .menu > li > button {
	width: 100%;
	height: 100%;
	text-align: center;
  
	background: none;
	color: inherit;
	border: none;
	padding: 5px;
	margin: 0;
	font: bold;
	cursor: pointer;
  }

.locationFilter >select{
	width:150px;
	// padding-right: 50px;
	
	text-align: center;
	font-size: medium;
	font-weight: bold;
	
}

.locationFilter > select > option{
	display: flex;
	width:100px;
	// max-width: 120px;
	height: 40px;
	// text-align: center;
	// font-size: medium;
	// font-weight: bold;
}
.site-nav {
	// justify-content: space-between;
	// display: flex;
	// width: 110px;
	font-size: 17px;
	// padding: 9px 0;
}

.user-nav {
	width: 15%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.login-signup {
	// width: 15%;
	display: flex;
	justify-content: flex-end;
}
.linksImp {
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	padding-left: 20px;
}
.left-nav > ul,
.right-nav > ul {
	display: flex;
	align-items: center;
}

.left-nav > ul > li {
	margin-left: 20px;
	cursor: pointer;
	text-transform: uppercase;
	font-family: $sans-serif;
	font-weight: bold;
	font-size: 11px;
	padding: 7px 9px;
	transition: background-color 0.3s;
}

.logo-img {
	// background-image: url('https://logo.clearbit.com/maascot.com');
	// background-size: 110%;
	// margin-bottom: 4px;
	// display: block;
	// position: relative;
	object-fit: cover;
	
	

}
.logo img {width: 150px;}
.logo {
	padding-left: 10px;
	// display: flex;
	height: 100%;
	width:200px;
	// zoom:150%
	
	// border: 3px solid red; 
}

.logo p {
	margin-left: 5px;
}

.logo:hover {
	cursor: pointer;
}

.logo-text-main {
	padding-top: 6px;
	color: $orange;
	font-size: 25px;
	font-weight: 800;
}

.logo-text-second {
	font-size: 15px;
	color: $text-gray;
	padding-top: 7px;
	font-weight: bold;
}

.user-profile-img-container {
	background-color: $gray;
	border-radius: 16px;
	width: 52px;
	height: 52px;
	display: flex;
	align-items: center;
	justify-content: center;

}

.user-profile-img-container:hover {
	cursor: pointer;
}

.user-profile-img {
	object-fit: cover;
	border-radius: 16px;
}

.search-bar-container {
	// width: 340px;
	// height: 34px;
	
}
.search-bar-container >div {
	display:grid;
	// border: 4px solid black;
}

.search-bar {
	
	// margin-top: 4px;
	
	width: 330px;
	height: 30px;
	font-size: 18px;
	background-color: $input-background-gray;
}
/* Dropdown Button */
.dropbtn-b {
	// background-color: #04AA6D;
	// color: white;
	// padding: 16px;
	font-size: 12px;
	text-transform: capitalize;
	margin-bottom: 10px;
	// text-decoration:double;
	

	// border: none;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown-b {
	position: relative;
	display: inline-block;
	// text-decoration:double;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content-b {
	display: none;
	position: absolute;
	// margin-top: 2px;
	// align-items:inherit;
	background-color: $background-gray;
	width: 150%;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content-b a {
	color: black;
	padding: 6px 8px;
	text-decoration: none;
	// text-align: left;
	display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content-b a:hover {background-color: #ddd;}
  
  /* Show the dropdown menu on hover */
  .dropdown-b:hover .dropdown-content-b {display: block;}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
//   .dropdown-b:hover .dropbtn-b {background-color: #ddd;}

@media all and (max-width: 1100px) {
	.linksImp {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
	}
  .right-nav button {
  	display: none;
  }
  .login-signup {
	display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 10px;
		margin-bottom: 10px;
  }
  .site-nav {
	padding-top: 20px;
	padding-bottom: 10px;
	width:90%
	// padding-left: 20px;
  }
  .login-signup{
	// display: flex;
	// padding-left: 210px;
  }
  .left-nav > ul > li {
  	margin: 0;
  }
  .main-nav {
	// margin-left: 110px;
	// width:50%;
  	// padding: 20px 0;
	// width: 120px;
	// display:table-cell;
	// text-align: center;
	// align-content: center;
	// align-items: center;
	// align-self: center;
	// position: relative;
	// box-sizing: border-box;
	

	}
	.search-bar-container{
		padding-left: 20px;
		// width:90%;
	}
	.headerColor{
		display: flex;
		flex-direction: column;
		height: 100%;
	}
	.headerLinks{
		background-color: black;
		color:white;
		font-size: 5px;
		justify-content: space-between;
		margin-left: 12px;
		margin-right: 12px;
		// margin-left: 100px;

	}
	.logo {
		// height: 80px;
		// width: 80px;
		width: 100%;
		// padding-left: 130px;
		// padding-right: 300px;
		// padding-bottom: 20px;
		display: grid;
		justify-content: center;
		padding-left:0;
		margin-left:0
	}
	.logo img{
		width: 150px;
		height: 120px;
		// margin-right: 100px;
		
	object-fit: cover;
	}
	.logo p {
		// display: none;
		padding-left: 0px;
		width: 170px;
		text-align: left;
	}
	.logo-text-main {
		// padding-right: 8px;
	}
}
.product-detail-container {
  max-width: 1110px;
  width: 95%;
  margin: 0 auto;
  background-color: $background-gray;
}

.product-detail-main-content-container {
  display: flex;
  justify-content: space-between;
}

.product-detail-content-bar {
  width: 720px;
}

.product-detail-content-bar > * {
  // margin: 0 10px 10px 10px;
  // border-radius: 2px;
  // background-color: $white;
  // box-sizing: border-box;
  // border: 1px solid $gray;
}

.product-detail-aside {
  width: 320px;
}

.product-detail-content-header {
  line-height: 16px;
  font-size: 10px;
  color: $text-gray;
  font-weight: 700;
  margin-left: 10px;
}

//Product Detail Header
.product-detail-header {
  height: 220px;
  padding: 30px 0;
  display: flex;
  //testå
}

.product-detail-header-img {
  // margin-left: 10px;
  height: 200px;
  width: 220px;
}

.product-detail-header-text {
  margin-left: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;


  //test
  // border: 1px solid red;
}

.product-detail-header-name {
  font-size: 18px;
}

.product-item-buttons {
  width: 100%;
  
  //test
  // border: 1px solid red;
}

.product-detail-header-tagline {
  font-size: 12px;
  color: $text-gray;
}

//Product Detail Media 
.product-detail-media {
  max-height: 300px;
  border: 1px solid #ddd;
  display: flex;
  overflow: hidden;
  overflow-x: auto;
  
}

.product-detail-media::--webkit-scrollbar{
  width: 0;
}

.product-detail-media .item{
  min-width: 300px;
  height: 300px;
  line-height: 200px;
  text-align: center;
  background-color: #ddd;
  margin-right: 2px;
}

//Product Detail Nav
.product-detail-nav {
  width: 320px;
  //test
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
}

.product-detail-nav-upvote-container {
  padding: 5px;
  border-bottom: 1px solid $gray;
}
.product-detail-nav-upvote-container > div{
  display: flex;
  align-items: center;
}

.product-detail-nav-link-container {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.product-detail-nav-link-container > * {
  margin: 5px;
}

//Product Discussion
.product-discussion {
  // margin-top: 130px;
  width: 720px;
  padding: 20px;

}

@media all and (max-width: 1100px) {

  .product-detail-container {
    padding-left: 0;
    // padding-right: 1200px;
    // max-width: 110px;
    // width: 95%;
    // margin: 0 auto;
    background-color: $background-gray;
  }
  .product-detail-content-bar{
    width: 13%;
  }
  .product-detail-main-content-container{
    display: grid;
  }
  .product-detail-aside {
    padding-left: 50px;
    display: flex;
    grid-row-start: 1;
  }
  
}

.product-reviews{
  margin-top: 40px;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.review-board{
  height: 100%;
  max-width: 500px;
  width: 100%;
  background-color: $gray;
  text-align: center;
  border-radius: 8px;
  padding: 4em 0;

}

.review-slide{
  
  height: 150px;
  max-width: 500px;
  // width: 100%;
  background-color: $gray;
  text-align: center;
  border-radius: 8px;
  padding: 4em 0;
}

.review-slide .review-flex{
  // margin: 1em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.review-board .review-slide .review-comments{
  background-color: white;
  padding: 1.5em 2em;
  width: 50%;
  border-radius: 5px;
  position: relative;
}

.review-slide .review-comments::after{
  content:'';
  position: absolute;
  bottom: -19px;
  left: 45%;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid var(white);
  z-index: -99;
}
.review-slide .profile{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
}

.review-slide .profile > a{
  text-decoration: none;
  color: black;

}

.review-slide .profile > span{
  color: black;
  font-size: .8em;

}

.product-addtional-information{
  margin-top: 30px;
  background-color: $gray;
}
.product-addtional-information > h1{
  color: $dark-gray;
  padding: 1em 1em;
  display: flex;
  height:10px;
}
.product-addtional-information > div{
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 1em;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid var(white);
  z-index: -99;
  padding: 4em 0;
  
}
.product-addtional-information > div >p{
  font-size: 15px;
  line-height: 25px;
  color: $dark-gray;
  font-weight: 700;
  margin: 10px 0 20px 5px;
}
.product-addtional-information > div > ul{
  display: flex;
  flex-direction: column;
  padding: 2em 2em 2px 2px;
  width: 33%;
  // background-color: $gray;
  // align-items: left;
}

.product-addtional-information > div > ul >li{
  font-size: 15px;
  color: $dark-gray;
  font-weight: 550;
}

.product-addtional-information > div > h2{
  // color: $dark-gray;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 1em 1em 1px 1px;
}
.tags{
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  width: 50%;
  flex-wrap: wrap;
  // float: right;
  // overflow-y:scroll; 
  //   position:relative;
  //   height: 100%;
 
  
  // height: 100%;
  // width:50%;
  
  // padding: 1em 1em 10px 10px;
}
.tags .tag{
  margin-top: .125rem;
    margin-bottom: .125rem;
    margin-right: .25rem;
    border-radius: .25rem;
    --tw-bg-opacity: 1;
    background-color: $gray;
    padding-left: .5rem;
    padding-right: .5rem;
    padding-top: 0;
    padding-bottom: 0;
  
  
  // padding: 1em 1em 10px 10px;
}


.product-addtional-information > div > ul > li {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-indent: 1em;
  padding: 10px 10px 2px 10px;
  // background-color: $gray;
  
}

.product-addtional-information > div > ul > li > img{
  height:18px;
  width: 18px;
}


.product-discussion .product-discussion-error > p{
  background-color: white;
  display: flex;
  align-items: center;
  margin-top: 1em;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid var(white);
  z-index: -99;
  padding: 4em 0;
}

.details{
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  
}
.industries{
  width:60%;
}

@media all and (max-width: 1100px) {
  .tags{
    display: flex;
    flex-direction: row;
    width: 50%;
    flex-wrap: wrap;
    margin-right: 20px;
    // float: right;
    overflow-y:scroll; 
      // position:relative;
      height: 70%;
   
    
    // height: 100%;
    // width:50%;
    
    // padding: 1em 1em 10px 10px;
  }
  .tags .tag{
    margin-top: .125rem;
      // margin-bottom: .125rem;
      margin-right: .25rem;
      border-radius: .25rem;
      --tw-bg-opacity: 1;
      background-color: $gray;
      padding-left: .5rem;
      padding-right: .5rem;
      padding-top: 0;
      padding-bottom: 0;
      height:20px
      // height: 20%;
    
    // padding: 1em 1em 10px 10px;
  }
  .loader{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
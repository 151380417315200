.change-password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    margin: 0 auto;
    height:420px;
    padding-top: 150px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    margin-bottom: 10px;
    font-size: medium;
    display: block;
    margin-bottom: 5px;
    color: #000;
  }
  
  .form-input {
    font-size: medium;
    width: 100%;
    padding: 8px;
    border: 1px solid #000;
    background-color: #fff;
    color: #000;
  }
  
  .form-button {
    font-size: medium;
    margin-top: 30px;
    padding: 8px 16px;
    border: none;
    background-color: #000;
    color: #fff;
    cursor: pointer;
  }
  
  .form-button:hover {
    background-color: #333;
  }
  .form-error{
    margin-top: 20px;
    font-size: 22px;
    font-weight: bolder;
    padding-left: 30px;
  }
  
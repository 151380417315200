button {
  margin-left: 5px;
	border-radius: 3px;
	transition: background-color 0.3s;
	font-family: $sans-serif;
	text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
	text-align: center;
}

.button {
  margin-left: 5px;
	border-radius: 3px;
	transition: background-color 0.3s;
	text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
	vertical-align: middle;
}

input[type=submit] {
  font-weight: bold;
  margin-left: 0;
	border-radius: 3px;
	transition: background-color 0.3s;
	font-family: $sans-serif;
	text-transform: uppercase;
  font-size: 11px;
  border: 0;
  font-weight: 600;
	text-align: center;
}

.large-size {
	min-width: 96px;
	padding: 16px 13px;
}

.medium-size {
  min-width: 80px;
  padding: 8px 13px
}

.small-size {
  height: 22px;
	padding: 0 5px;
	min-width: 30px;
	font-size: 11px;
	line-height: 16px;
	font-weight: 700;
	margin-left: 10px;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.white-button {
	background-color: $white;
	border: 1px solid $gray;
;
	// color: $black;
}

.white-button:hover {
	background-color: $gray;
	text-decoration: none;
}

.gray-text {
	color: $text-gray;
}

.orange-border-button {
	background-color: $white;
	border-color: $orange;
	border: 1px solid $orange;
	color: $orange;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.orange-button {
	background-color: $orange;
	border-color: $orange;
	border: 1px solid $orange;
	color: white;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.orange-button:hover {
	background-color: $light-orange;
	border-color: $orange;
}

.herobutt{
	display: flex;
}

.hero-button {
//   background-color: $orange;
background-color: black;
	// border-color: $orange;
	border-color: black;
	border: 1px solid black;
	align-items: center;
	color: white;
	display: flex;
	justify-content: space-around;
	font-weight: 700;
	height: 42px;
	width: 54px;
	padding: 0 30px;
	font-size: 13px;
}

.hero-button:hover {
	background-color: $light-orange;
	border-color: $orange;
}
 
.link-button {
	color: $text-gray;
	margin-right: 5px;
}

.orange-link-button {
	color: $orange;
}

.upvotes {
	width: 76px;
	display: flex;
	justify-content: space-between;
}

.link-button a {
	margin-left: 3px;
}

.link-button a:hover {
	text-decoration: underline;
}
//Search Feed
.search-feed {
  display: flex;
  width: 80%
}

//Search Index
.search-index-container {
  background-color: $white;
  width: 75%;
  margin: 0 auto;
  border: 1px solid $gray;
  border-radius: 1px;
}

.search-index {

}

.search-index-header {
  border-bottom: 1px solid $gray;
  height: 21px;
  padding: 15px 0;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-index-header-text {
  font-size: 20px;
}

@media all and (max-width: 1100px) {
.search-feed{
  display: grid;
  width: 100%;
}
.search-index-container{
  width: 97%;
}
.product-nav{
  
  width:100%;
  // display: none;
  // float: left;
  // display: inline-block;
  // position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  // grid-template-columns: 1fr 1fr;
  // width:50%;
}
.product-nav-header{
  font-size: 18px;
  display: block;

}
.product-nav-list{
  // display: grid;
  // display:none;
  // list-style: none;

    // position: absolute;
}
// .product-nav-list li{
// display: grid;
// }

// .product-nav-list li ~ li{
//   display: none;
//   }
// .product-nav-list:hover .product-nav-list li ~ li{
//   display: grid;
//   }
.product-nav:hover ul {
  // display: inline-block;
}
}
.contact-us {
    color: black;
    font-family: Arial, sans-serif;
    line-height: 1.5;
    margin: 20px;
    margin-top: 100px;
    // max-width: 800px;
  }
  
  .contact-us__heading {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .contact-us__subheading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .contact-us__text {
    font-size: 24px;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  .contact-us__list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 24px;
  }
  
  .contact-us__item {
    margin-bottom: 10px;
  }
  
  .contact-us__link {
    color: black;
    text-decoration: underline;
  }
  
  /* Styling for links when hovered */
  .contact-us__link:hover {
    color: black;
    text-decoration: none;
  }


  
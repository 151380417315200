.col {
  float: left;
  box-sizing: border-box;
}

/* Attribute selector targeting all elements
with a class attribute containing 'col-' */
[class*='col-'] {
  padding-right: 20px;
}

[class*='col-']:last-of-type {
  padding-right: 0;
}

.col-2-3 {
  width: 66.6666%;
}

.col-1-2 {
  width: 50%;
}

.col-1-3 {
  width: 33.3333%;
}

@media all and (max-width: 1000px) {
  [class*='col-'] {
    width: 100%;
  }
}
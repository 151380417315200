.about-container {
    padding: 40px;
    margin: 40px;
    color: black;
  }
  
  .about-heading {
    font-size: 2em;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .about-text {
    font-size: 1.2em;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  .about-subheading {
    font-size: 1.5em;
    margin-top: 40px;
    margin-bottom: 20px;
    animation: slideUp 1s ease;
  }
  
  .about-team {
    display: flex;
    justify-content: center;
    align-items: center;
    // flex-wrap: wrap;
    flex-direction: column;
    margin-top: 40px;
  }
  .about-team-img {
    display: flex;
    justify-content: center;
    align-items: center;
    // width:60%;
    height:330px
  }
  // @media all and (max-width: 1100px){
  //   .about-container{
  //     margin-top: 200px;
  //   }
  // }
  
  @media all and (max-width: 1100px){
    .about-team-img{
      height:150px
    }
  }
html {
	position: relative;
	height: 100%;
}

body {
	width: 100%;
	height: 100%;
	margin: 0 auto;
	font-family: $sans-serif;
	font-size: 12px;
}

#root {
	height: 100%
}

.app { 
	overflow: auto;
	background-color: $background-gray;
	height: 100%;
}

header {
	background-color: $white;
	width: 100%;
	border-bottom: 1px solid $gray;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	// bottom:90%;
	// bottom: 10px;
	z-index: 999;
	
}

a:hover {
	text-decoration: underline;
}

.hidden {
	display: none;
}

/*
	Font Elements
*/

h1 {
	font-size: 24px;
	font-weight: bold;
}

h2 {
	font-size: 22px;
	text-align: center;
	margin-bottom: 10px;
	font-weight: 600;
}

p {
	line-height: 16px;
}

.intro { 
	color: $gray;
	font-size: 16px;
}

img {
	width: 100%;
	height: 100%;
}

small {
	font-size: 9px;
	color: $gray;
	font-family: $sans-serif;
}

/*
	Header Rules
*/

hr {
	border: 0;
	height: 1px;
	background-color: $gray;
}

.hr-top, .hr-bottom {
	width: 100%;
	margin: 0;
}

.hr-top {
	height: 2px;
	margin-bottom: 1px;
}

/*
	Images
*/

.thumbnail {
	height: 100px;
}

.thumbnail img {
	object-fit: cover;
}

/*
	Inputs
*/

.input-wrapper {
	display: flex;
	margin: 5px 0;
}

.input-wrapper input {
	flex: 1;
	font-family: $sans-serif;
	padding: 0 10px;
	border: 1px solid $background-gray;
	border-right: none;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.input-wrapper button {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

input {
	border: 1px solid $gray;
	border-radius: 5px;
	margin-left: 5px;
	padding-left: 5px;
	background-color: $white;
}

input[type="search"] {
	padding: 5px;
}

// Text Area
.textarea-container {
	position: relative;
	width: 100%;
	max-height: 400px;
}

textarea {
	box-sizing: border-box;
	// height: 100%;
	min-height: 16px;
	max-height: 500px;
	text-justify: center;
	// visibility: hidden;
	// width: calc(100% - 10px);
	position: absolute;

	padding: 7px 0 7px 10px;
	background-color: $white;
	overflow: hidden;
	word-wrap: break-word;
	resize: none;
	border: 1px solid $gray;
	border-radius: 3px;
}

.text-copy {
	box-sizing: border-box;
	padding: 7px 10px;

	visibility: hidden;
	width: 100%;
	max-height: 500px;
}